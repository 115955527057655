import type { PointsLevel } from '@kwenta/sdk/types'
import { calculateLevelProgress, formatNumber, getNextThreshold } from '@kwenta/sdk/utils'
import StartIcon from 'assets/svg/app/yellow-star.svg'
import { FlexDivCol, FlexDivRow, FlexDivRowCentered } from 'components/layout/flex'
import { type FC, memo, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useAppSelector } from 'state/hooks'
import { selectLevelThresholds } from 'state/points/selectors'
import styled from 'styled-components'

import PointsRankAvatar from './PointsRankAvatar'
import ProgressBar from './ProgressBar'
import RectangleConfetti from './RectangleConfetti'
import Spacer from './Spacer'
import { Body } from './Text'

type NotificationProps = {
	points: number
	level: PointsLevel
}

const LevelUpNotification: FC<NotificationProps> = memo(({ points, level }: NotificationProps) => {
	const { t } = useTranslation()

	const levelThresholds = useAppSelector(selectLevelThresholds)
	const progress = calculateLevelProgress(points)
	const nextThreshold = useMemo(() => {
		// use frontend hardcoded level thresholds if backend level thresholds are not available
		if (levelThresholds.length === 0) {
			return getNextThreshold(points)
		}
		return getNextThreshold(points, levelThresholds)
	}, [levelThresholds, points])

	return (
		<FlexDivCol rowGap="15px">
			<Body size="large" weight="semiBold" color="primary">
				{t('common.points.notification-title.level-up')}
			</Body>
			<Divider height={1} divider />
			<FlexDivRowCentered columnGap="10px">
				<StyledPointsRankAvatar width={54} height={54} level={level} />
				<FullWidthFlexDivCol rowGap="2px">
					<Level>{level}</Level>
					<Body> {t('common.points.level-up')}</Body>
				</FullWidthFlexDivCol>
			</FlexDivRowCentered>
			<FlexDivCol rowGap="5px">
				<FlexDivRow>
					<StyledBody mono weight="regular" size={'large'}>
						{level}
					</StyledBody>
					<FlexDivRowCentered columnGap="3px">
						<StartIcon width={14} height={14} />
						<StyledBody type="span" mono weight="regular" size="large" color="preview">
							{formatNumber(points, { maxDecimals: 0 })}
						</StyledBody>
						<Body>/</Body>
						<StyledBody type="span" mono weight="regular" size="large">
							{nextThreshold ? formatNumber(nextThreshold, { maxDecimals: 0 }) : '∞'}
						</StyledBody>
					</FlexDivRowCentered>
				</FlexDivRow>
				<ProgressBar ratio={progress} height={8} />
			</FlexDivCol>
		</FlexDivCol>
	)
})

type EarnPointsNotificationProps = NotificationProps & {
	newPoints: number
}

const EarnPointsNotification: FC<EarnPointsNotificationProps> = memo(
	({ points, level, newPoints }: EarnPointsNotificationProps) => {
		const { t } = useTranslation()

		const levelThresholds = useAppSelector(selectLevelThresholds)
		const progress = calculateLevelProgress(points)
		const nextThreshold = useMemo(() => {
			// use frontend hardcoded level thresholds if backend level thresholds are not available
			if (levelThresholds.length === 0) {
				return getNextThreshold(points)
			}
			return getNextThreshold(points, levelThresholds)
		}, [levelThresholds, points])

		return (
			<FlexDivCol rowGap="15px">
				<RectangleConfetti />
				<Body size="large" weight="semiBold" color="primary">
					{t('common.points.notification-title.earn')}
				</Body>
				<Divider height={1} divider />
				<ContentContainer columnGap="10px">
					<FullWidthFlexDivCol rowGap="2px">
						<FlexDivRowCentered columnGap="5px" justifyContent="left">
							<StartIcon width={20} height={20} />
							<Level>{formatNumber(newPoints, { maxDecimals: 1 })}</Level>
						</FlexDivRowCentered>
						<Body>{t('common.points.points-earned')}</Body>
					</FullWidthFlexDivCol>
				</ContentContainer>
				<FlexDivCol rowGap="5px">
					<FlexDivRow>
						<StyledBody mono weight="regular" size={'large'}>
							{level}
						</StyledBody>
						<FlexDivRowCentered columnGap="3px">
							<StartIcon width={14} height={14} />
							<StyledBody type="span" mono weight="regular" size="large">
								{formatNumber(points, { maxDecimals: 0 })}
							</StyledBody>
							<Body>/</Body>
							<StyledBody type="span" mono weight="regular" size="large">
								{nextThreshold ? formatNumber(nextThreshold, { maxDecimals: 0 }) : '∞'}
							</StyledBody>
						</FlexDivRowCentered>
					</FlexDivRow>
					<ProgressBar ratio={progress} height={8} />
				</FlexDivCol>
			</FlexDivCol>
		)
	}
)

const StyledPointsRankAvatar = styled(PointsRankAvatar)`
	filter: drop-shadow(0px 4px 14px rgba(255, 184, 1, 0.36));
`

const StyledBody = styled(Body)<{ invisible?: boolean }>`
	visibility: ${(props) => (props.invisible ? 'hidden' : 'visible')};
`

const Level = styled(Body)`
	color: ${(props) => props.theme.colors.selectedTheme.newTheme.text.primary};
	font-size: 20px;
	font-weight: 700;
`

const FullWidthFlexDivCol = styled(FlexDivCol)`
	width: 100%;
`

const Divider = styled(Spacer)`
	width: 100%;
`

const ContentContainer = styled(FlexDivRowCentered)`
	height: 60px;
`

export { LevelUpNotification, EarnPointsNotification }
