import styled, { css } from 'styled-components'

export const MenuButton = styled.div<{
	isActive?: boolean
	isFocused?: boolean
	isLink?: boolean
}>`
	outline: none;
	width: 100%;
	font-size: 19px;
	font-weight: 700;
	span {
		color: ${(props) => props.theme.colors.selectedTheme.newTheme.text.secondary};
	}
	text-transform: capitalize;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	height: 100%;

	& .caret {
		color: ${(props) => props.theme.colors.selectedTheme.newTheme.text.secondary};
		transition: transform 0.3s ease;
		transform: rotate(0deg);
	}

	${(props) =>
		props.isActive &&
		css`
			& > div::before {
				position: absolute;
				left: 0;
				top: 0;
				content: '';
				width: 3px;
				height: 50px;
				background-color: ${props.theme.colors.selectedTheme.newTheme.dashboard.menu.indicator};
			}
			span {
				color: ${props.theme.colors.selectedTheme.newTheme.text.primary};
			}

			& svg {
				color: ${(props) => props.theme.colors.selectedTheme.newTheme.text.primary};
			}
		`};

	${(props) =>
		props.isFocused &&
		css`
			.menu-title {
				// background-color: ${props.theme.colors.common.palette.alpha.white16};
			}

			& .caret {
				transform: rotate(180deg);
			}
		`};
`
