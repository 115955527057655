import { type TruncateUnits, formatNumber } from '@kwenta/sdk/utils'
import styled from 'styled-components'

import { Body } from 'components/Text'
import useGetFuturesCumulativeStats from 'queries/futures/useGetFuturesCumulativeStats'
import media from 'styles/media'

const Stats = () => {
	const stats = useGetFuturesCumulativeStats()
	const isDataReady = stats.isSuccess && stats.data

	const formatData = (data?: string, truncateOver?: TruncateUnits) => {
		return formatNumber(Number(data ?? '0'), {
			truncateOver,
			maxDecimals: 0,
		})
	}

	return (
		isDataReady && (
			<Container>
				<StatsData>
					<Title>volume</Title>
					<Data mono>${formatData(stats.data?.totalVolume, 1e6)}+</Data>
				</StatsData>
				<StatsData>
					<Title>traders</Title>
					<Data mono>{formatData(stats.data?.totalTraders, 1e3)}+</Data>
				</StatsData>
				<StatsData>
					<Title>trades</Title>
					<Data mono>{formatData(stats.data?.totalTrades, 1e3)}+</Data>
				</StatsData>
			</Container>
		)
	)
}

export default Stats

const Container = styled.div`
	display: flex;
	flex-direction: column;
	gap: 50px;
	position: absolute;
	top: 40px;
	right: 40px;
	z-index: 20;

	${media.lessThan('lg')`
		right: 20px;
    bottom: 120px;
    top: unset;
  `}
`

const StatsData = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-end;
	gap: 10px;
`

const Title = styled(Body)`
	text-transform: uppercase;
	font-size: 14px;
	font-weight: 400;
	line-height: 14px;
	letter-spacing: 0.04em;
	color: ${(props) => props.theme.colors.common.neautralGray};
`

const Data = styled(Body)`
	font-size: 24px;
	font-weight: 400;
	line-height: 24px;
	letter-spacing: -0.02em;
	background: ${(props) => props.theme.colors.common.palette.gradient.greenCyan500};
	background-clip: text;
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
`
