import type {
	DepositableAssetKeysV3Type,
	DepositableV3Assets,
	SynthAssetKeysV3,
} from '@kwenta/sdk/constants'
import {
	type FuturesMarketAsset,
	type FuturesTrade,
	type FuturesVolumes,
	type Period,
	type PerpsMarketV2,
	type PerpsMarketV3,
	PerpsProvider,
	type PerpsV2Position,
	type PerpsV3Liquidation,
	type PerpsV3Position,
	type PositionSide,
	type SwapDepositToken,
} from '@kwenta/sdk/types'
import type Wei from '@kwenta/wei'
import type { SessionInfo } from 'types/accountAbstraction'

import type { HistoryTab } from 'sections/dashboard/History/HistoryTabs'
import type { TableFilter } from 'sections/futures/UserInfo/TableFilters/TableFilters'
import type { FuturesTab } from 'sections/futures/UserInfo/UserInfoTabs'
import type { QueryStatus } from 'state/types'

import type { Hex } from 'viem'
import type { ClosePositionInputs, HistoricalFundingRates, TradePanelInputs } from './common/types'
import type { IsolatedMarginAccountData, IsolatedMarginTradePreview } from './isolatedMargin/types'
import type { BalanceSpenders, SnxPerpsV3TradePreview, SnxV3AccountData } from './snxPerpsV3/types'

export type PortfolioValues = {
	timestamp: number
	total: number
}

export type InputCurrencyDenomination = 'usd' | 'native'

export type FundingRatePeriods = {
	[key: number]: string
}

export enum TradingModes {
	DEFAULT = 'DEFAULT',
	ONE_CLICK = 'ONE_CLICK',
}

export enum ManageModalType {
	Deposit = 0,
	Withdraw = 1,
	Bridge = 2,
	OneClick = 3,
	Delegation = 4,
	Orders = 5,
}

export type SLTPInputs<T = Wei> = {
	stopLossPrice?: T
	takeProfitPrice?: T
}

export type EditConditionalOrderInputs<T = Wei> = {
	orderId?: number
	margin?: T
	size?: T
	orderPrice: {
		price?: T
		invalidLabel?: string
	}
}

export type EditPositionInputs<T = Wei> = {
	nativeSizeDelta: T
	marginDelta: T
}

export type FuturesPreferences = {
	selectedManageModalType: ManageModalType
	selectedSwapDepositToken: SwapDepositToken
	selectedInputHours: number
	selectedChart: 'price' | 'funding' | 'market_details'
	selectedInputDenomination: InputCurrencyDenomination
	selectedProvider: PerpsProvider
	selectedAllNetworks: boolean
	showHistory?: boolean
	showFavorites?: boolean
	showPositionLines?: boolean
	showOrderLines?: boolean
	showRealizedPnL?: boolean
	showTestnets?: boolean
	showFunding?: boolean
	hidePoints?: boolean
	tradingMode: TradingModes
	unlimitedApproval?: boolean
	userInfoCollapsed: boolean
	userInfoShowAllMarkets: boolean
	historicalFundingRatePeriod: Period
	favoriteMarkets: FuturesMarketAsset[]
	profitCalculatorVisible: boolean
	portfolioChartType: 'account' | 'pnl'
	userInfoTableFilter: {
		[key in TableFilteredFuturesTabs]: TableFilter | null
	}
	dashboardHistoryTableFilter: {
		[key in HistoryTab]: DashboardHistoryTableFilter | null
	}
}

type WalletAddress = string

export type ProviderData = {
	markets: {
		[PerpsProvider.SNX_V2_OP]: PerpsMarketV2<string>[]
		[PerpsProvider.SNX_V3_BASE]: PerpsMarketV3<string>[]
		[PerpsProvider.SNX_V3_ARB]: PerpsMarketV3<string>[]
	}
	supportedCollaterals: Partial<Record<PerpsProvider, SynthAssetKeysV3[]>>
	dailyMarketVolumes: Partial<Record<PerpsProvider, FuturesVolumes<string>>>
	historicalFundingRates: Partial<Record<PerpsProvider, HistoricalFundingRates>>
	globalTradeHistory: Record<
		PerpsProvider,
		Partial<Record<FuturesMarketAsset, FuturesTrade<string>[]>>
	>
	globalLiquidationHistory: Partial<
		Record<PerpsProvider, Record<string, PerpsV3Liquidation<string>[]>>
	>
	totalFeesPaid: Partial<Record<PerpsProvider, string>>
	maxDepositAmounts: Partial<
		Record<PerpsProvider, Partial<Record<DepositableAssetKeysV3Type, string>>>
	>
	spenders: Partial<Record<PerpsProvider, BalanceSpenders>>
}

export type AccountsData = {
	[PerpsProvider.SNX_V2_OP]: Record<WalletAddress, IsolatedMarginAccountData>
	[PerpsProvider.SNX_V3_BASE]: Record<WalletAddress, SnxV3AccountData>
	[PerpsProvider.SNX_V3_ARB]: Record<WalletAddress, SnxV3AccountData>
}

export type DebtPaymentQuote = {
	token: DepositableV3Assets
	amountIn: string
	amountOut: string
	indexPrice: string
	quotedPrice: string
	priceImpact: string
	path: Hex
}

export type FuturesState = {
	selectedMarketAsset: Record<PerpsProvider, FuturesMarketAsset>
	preferences: FuturesPreferences
	acknowledgementTerms: Record<string, boolean>
	queryStatuses: Partial<Record<string, QueryStatus>>
	oneClickTrading: {
		accountDelegated: boolean
		ethBalance: string
		usdcBalance: string
		usdcEngineAllowance: string
		sessionInfo?: SessionInfo
		isSessionActive: boolean
		estTxCost: string
	}
	dashboard: {
		selectedPortfolioTimeframe: Period
	}
	leaderboard: {
		selectedTrader?: { trader: string; traderEns?: string | null; accountId?: string }
		selectedTraderPositionHistory: {
			[PerpsProvider.SNX_V2_OP]?: Record<string, PerpsV2Position<string>[]>
			[PerpsProvider.SNX_V3_BASE]?: Record<string, PerpsV3Position<string>[]>
			[PerpsProvider.SNX_V3_ARB]?: Record<string, PerpsV3Position<string>[]>
		}
		pageSize: number
	}
	sltpModalInputs: SLTPInputs<string>
	editConditionalOrderInputs: EditConditionalOrderInputs<string>
	editPositionInputs: EditPositionInputs<string>
	keeperDeposit?: string
	tradePanelDrawerOpen: boolean
	cancellingConditionalOrder?: number | undefined
	snxV2: {
		swapDepositSlippage: number
		swapDepositCustomSlippage: string
	}
	snxV3: {
		debtPaymentQuote?: DebtPaymentQuote
	}
	delegation: {
		selectedDelegationAddress: string
		selectedSubAccountAddress: string
		newNickname: string
		addressBook: Record<string, Array<DelegationAccountInfo>>
		subAccountsForWallet: {
			[PerpsProvider.SNX_V2_OP]: Record<string, Array<DelegationAccountInfo>>
			[PerpsProvider.SNX_V3_BASE]: Record<string, Array<DelegationAccountInfo>>
			[PerpsProvider.SNX_V3_ARB]: Record<string, Array<DelegationAccountInfo>>
		}
	}
	closePositionOrderInputs: ClosePositionInputs<string>
	tradePanel: {
		leverageSide: PositionSide
		leverageInput: string
		slippageInput: string
		inputs: TradePanelInputs<string>
	}
	tradePreviews: {
		[PerpsProvider.SNX_V2_OP]?: IsolatedMarginTradePreview<string>
		[PerpsProvider.SNX_V3_BASE]?: SnxPerpsV3TradePreview<string>
		[PerpsProvider.SNX_V3_ARB]?: SnxPerpsV3TradePreview<string>
	}
	previewDebounceCount: number
	providerData: ProviderData
	accounts: AccountsData
}

export type ExecuteDelayedOrderInputs = {
	marketAsset: FuturesMarketAsset
	marketAddress: string
	originTxHash: `0x${string}`
}

export type DelegationAccountInfo = {
	address: string
	nickname: string
}

export type UIPreferencesPayload = {
	showHistory?: boolean
	showFavorites?: boolean
	showPositionLines?: boolean
	showOrderLines?: boolean
	showRealizedPnL?: boolean
	showTestnets?: boolean
	hidePoints?: boolean
	showFunding?: boolean
	tradingMode?: TradingModes
	unlimitedApproval?: boolean
	userInfoCollapsed?: boolean
	userInfoShowAllMarkets?: boolean
	historicalFundingRatePeriod?: Period
	selectedProvider?: PerpsProvider
	selectedAllNetworks?: boolean
	selectedInputDenomination?: InputCurrencyDenomination
	selectedChart?: 'price' | 'funding' | 'market_details'
	selectedInputHours?: number
	selectedSwapDepositToken?: SwapDepositToken
	selectedManageModalType?: ManageModalType
	portfolioChartType?: 'account' | 'pnl'
}

export type TableFilteredFuturesTabs = Extract<
	FuturesTab,
	FuturesTab.TRADES | FuturesTab.TRANSACTION_HISTORY | FuturesTab.ORDER_HISTORY
>

export type TradeHistory = {
	market: {
		asset: FuturesMarketAsset
		marketName: string
	}
	side: PositionSide
	orderType: string
	amount: string
	notionalValue: string
	interestCharged?: string
	pnl: string
	netPnl: string
	funding: string
	price: string
	feesPaid: string
	txnHash?: string
	displayAsset?: string | null
	timestamp: string | number
}

export type DashboardHistoryTableFilter = {
	asset?: string
	from?: string
	to?: string
	network?: string
	type?: string
	status?: string
	side?: string
	search?: string
}

export type PartialAccountData = Pick<IsolatedMarginAccountData | SnxV3AccountData, 'provider'> &
	Pick<IsolatedMarginAccountData | SnxV3AccountData, 'account'> &
	Pick<IsolatedMarginAccountData | SnxV3AccountData, 'network'> &
	Partial<IsolatedMarginAccountData | SnxV3AccountData>

export type PartialCrossMarginAccountData = Pick<SnxV3AccountData, 'provider'> &
	Pick<SnxV3AccountData, 'account'> &
	Pick<SnxV3AccountData, 'network'> &
	Partial<SnxV3AccountData>

export type SerializedPosition<T> = T extends PerpsV2Position<Wei>
	? PerpsV2Position<string>
	: PerpsV3Position<string>

export type UnserializedPosition<T> = T extends PerpsV2Position<string>
	? PerpsV2Position<Wei>
	: PerpsV3Position<Wei>
