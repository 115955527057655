import styled from 'styled-components'

import Arbitrum from 'assets/svg/partners/landing/arbitrum-white.svg'
import Base from 'assets/svg/partners/landing/base-white.svg'
import Optimism from 'assets/svg/partners/landing/optimism-white.svg'
import media from 'styles/media'

const EnabledChains = () => {
	return (
		<Container>
			<ChainContainer>
				<Base />
			</ChainContainer>
			<ChainContainer>
				<Optimism />
			</ChainContainer>
			<ChainContainer>
				<Arbitrum />
			</ChainContainer>
		</Container>
	)
}

export default EnabledChains

const Container = styled.div`
	display: flex;
	gap: 20px;
	position: absolute;
	bottom: 20px;
	left: $0px;
	z-index: 20;
	${media.lessThan('lg')`
  	bottom: 91px;
	`}

	> div:not(:last-child) {
    border-right: 1px solid rgba(255, 255, 255, 0.12);
	}
`

const ChainContainer = styled.div`
		padding-right: 20px;
`
