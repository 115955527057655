import styled from 'styled-components'

import { DesktopOnlyView, MobileOrTabletView } from 'components/Media'

const BackgroundVideo = () => {
	return (
		<>
			<DesktopOnlyView>
				<Video autoPlay muted loop playsInline>
					<source src="videos/SynthetixExchange_Desktop.mp4" type="video/mp4" />
					Your browser does not support HTML5 video.
				</Video>
			</DesktopOnlyView>
			<MobileOrTabletView>
				<Video autoPlay muted loop playsInline>
					<source src="/videos/Synthetix_Exchange_Mobile.webm" type="video/webm" />
					Your browser does not support HTML5 video.
				</Video>
			</MobileOrTabletView>
			<VideoOverLay />
		</>
	)
}

export default BackgroundVideo

const Video = styled.video`
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translateX(-50%) translateY(-50%);
	min-width: 100%;
	min-height: 100%;
	z-index: 0;
`

const VideoOverLay = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 10;
	background-color: ${(props) => props.theme.colors.common.dark.darkBackground};
	opacity: 0.7;
`
