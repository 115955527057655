import type { PerpsProvider } from '@kwenta/sdk/types'
import { useRouter } from 'next/router'
import { memo, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import TradingRewardsIcon from 'assets/svg/app/gift.svg'
import { Body } from 'components/Text'
import { FlexDivCol, FlexDivRow } from 'components/layout/flex'
import { PROVIDER_MAINNET_CHAINS } from 'constants/network'
import { selectPerpsProvider } from 'state/futures/common/selectors'
import { useAppSelector } from 'state/hooks'

import Connector from 'containers/Connector'
import { useMarketPageRoute } from 'hooks/useMarketPageRoute'
import type { NetworkItem } from '../constants'

type FuturesDropDown = {
	networks: NetworkItem[]
	onDismiss: () => void
}

const MobileIntegrationsDropDown = memo(({ networks, onDismiss }: FuturesDropDown) => {
	const { t } = useTranslation()
	const provider = useAppSelector(selectPerpsProvider)
	const { switchToChain } = Connector.useContainer()
	const { push } = useRouter()
	const marketPageRoute = useMarketPageRoute()

	const handleProviderSwitch = useCallback(
		(provider: PerpsProvider) => {
			switchToChain(PROVIDER_MAINNET_CHAINS[provider])
			push(marketPageRoute(provider))
			onDismiss()
		},
		[onDismiss, push, switchToChain, marketPageRoute]
	)

	return networks
		.filter((n) => n.enabled)
		.map(({ name, icon, provider: providerName, hasRewards }) => {
			return (
				<Container key={name}>
					<ProviderContainer
						isActive={provider === providerName}
						onClick={() => handleProviderSwitch(providerName)}
					>
						<NetworkNameRow>
							{icon}
							<Body weight="bold" size="medium" mono color="preview">
								{t(name).toUpperCase()}
							</Body>
							{hasRewards && <StyledTradingRewardsIcon />}
						</NetworkNameRow>
					</ProviderContainer>
				</Container>
			)
		})
})

const ProviderContainer = styled(FlexDivCol)<{ isActive: boolean }>`
	width: 100%;
	height: 35px;
	justify-content: center;
	border-radius: 8px;
	padding: 0 10px;

	background-color: ${(props) =>
		props.isActive ? props.theme.colors.common.palette.alpha.white16 : 'transparent'};
`

const Container = styled(FlexDivCol)`
	gap: 10px;
	margin-bottom: 10px;
`

const NetworkNameRow = styled(FlexDivRow)`
	gap: 5px;
	justify-content: flex-start;
	align-items: center;
	width: 100%;
	bottom: 10px;
`

const StyledTradingRewardsIcon = styled(TradingRewardsIcon)<{ disabled: boolean }>`
	path {
		fill: ${(props) => props.theme.colors.selectedTheme.newTheme.text.preview};
	}
`

export default MobileIntegrationsDropDown
