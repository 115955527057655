export var SupportedNetworkIds;
(function (SupportedNetworkIds) {
    SupportedNetworkIds[SupportedNetworkIds["ETHEREUM_MAINNET"] = 1] = "ETHEREUM_MAINNET";
    SupportedNetworkIds[SupportedNetworkIds["OPTIMISM_MAINNET"] = 10] = "OPTIMISM_MAINNET";
    SupportedNetworkIds[SupportedNetworkIds["OPTIMISM_SEPOLIA"] = 11155420] = "OPTIMISM_SEPOLIA";
    SupportedNetworkIds[SupportedNetworkIds["BASE_SEPOLIA"] = 84532] = "BASE_SEPOLIA";
    SupportedNetworkIds[SupportedNetworkIds["BASE_MAINNET"] = 8453] = "BASE_MAINNET";
    SupportedNetworkIds[SupportedNetworkIds["ARB_MAINNET"] = 42161] = "ARB_MAINNET";
    SupportedNetworkIds[SupportedNetworkIds["ARB_SEPOLIA"] = 421614] = "ARB_SEPOLIA";
})(SupportedNetworkIds || (SupportedNetworkIds = {}));
export var SnxV3NetworkIds;
(function (SnxV3NetworkIds) {
    SnxV3NetworkIds[SnxV3NetworkIds["BASE_SEPOLIA"] = 84532] = "BASE_SEPOLIA";
    SnxV3NetworkIds[SnxV3NetworkIds["BASE_MAINNET"] = 8453] = "BASE_MAINNET";
    SnxV3NetworkIds[SnxV3NetworkIds["ARB_SEPOLIA"] = 421614] = "ARB_SEPOLIA";
    SnxV3NetworkIds[SnxV3NetworkIds["ARB_MAINNET"] = 42161] = "ARB_MAINNET";
})(SnxV3NetworkIds || (SnxV3NetworkIds = {}));
export var BaseNetworkIds;
(function (BaseNetworkIds) {
    BaseNetworkIds[BaseNetworkIds["BASE_SEPOLIA"] = 84532] = "BASE_SEPOLIA";
    BaseNetworkIds[BaseNetworkIds["BASE_MAINNET"] = 8453] = "BASE_MAINNET";
})(BaseNetworkIds || (BaseNetworkIds = {}));
export var SnxV2NetworkIds;
(function (SnxV2NetworkIds) {
    SnxV2NetworkIds[SnxV2NetworkIds["OPTIMISM_MAINNET"] = 10] = "OPTIMISM_MAINNET";
    SnxV2NetworkIds[SnxV2NetworkIds["OPTIMISM_SEPOLIA"] = 11155420] = "OPTIMISM_SEPOLIA";
})(SnxV2NetworkIds || (SnxV2NetworkIds = {}));
export var ArbNetworkIds;
(function (ArbNetworkIds) {
    ArbNetworkIds[ArbNetworkIds["ARB_MAINNET"] = 42161] = "ARB_MAINNET";
    ArbNetworkIds[ArbNetworkIds["ARB_SEPOLIA"] = 421614] = "ARB_SEPOLIA";
})(ArbNetworkIds || (ArbNetworkIds = {}));
export var TransactionStatus;
(function (TransactionStatus) {
    TransactionStatus["AwaitingExecution"] = "AwaitingExecution";
    TransactionStatus["Executed"] = "Executed";
    TransactionStatus["Confirmed"] = "Confirmed";
    TransactionStatus["Failed"] = "Failed";
})(TransactionStatus || (TransactionStatus = {}));
export const NetworkIdByName = {
    mainnet: 1,
    'sepolia-ovm': 11155420,
    'mainnet-ovm': 10,
    'mainnet-fork': 31337,
    'base-sepolia': 84532,
    'base-mainnet': 8453,
    arbitrum: 42161,
    'arbitrum-sepolia': 421614,
};
export const NetworkNameById = {
    1: 'mainnet',
    10: 'mainnet-ovm',
    11155420: 'sepolia-ovm',
    31337: 'mainnet-fork',
    84532: 'base-sepolia',
    8453: 'base-mainnet',
};
export var Period;
(function (Period) {
    Period["ONE_HOUR"] = "ONE_HOUR";
    Period["FOUR_HOURS"] = "FOUR_HOURS";
    Period["ONE_DAY"] = "ONE_DAY";
    Period["ONE_WEEK"] = "ONE_WEEK";
    Period["TWO_WEEKS"] = "TWO_WEEKS";
    Period["ONE_MONTH"] = "ONE_MONTH";
    Period["THREE_MONTHS"] = "THREE_MONTHS";
    Period["ONE_YEAR"] = "ONE_YEAR";
})(Period || (Period = {}));
