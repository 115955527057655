import { PositionSide } from '@kwenta/sdk/types'
import { truncateAddress } from '@kwenta/sdk/utils'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { type FC, useCallback, useMemo, useReducer } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import CloseIcon from 'assets/svg/app/close.svg'
import MenuIcon from 'assets/svg/app/menu.svg'
import Button from 'components/Button'
import { TransactionButton } from 'components/Button/Button'
import ErrorView from 'components/ErrorView'
import Spacer from 'components/Spacer'
import ROUTES from 'constants/routes'
import { zIndex } from 'constants/ui'
import { MOBILE_FOOTER_HEIGHT } from 'constants/ui'
import Connector from 'containers/Connector'
import useENS from 'hooks/useENS'
import { setOpenModal } from 'state/app/reducer'
import { createIsolatedMarginAccount } from 'state/futures/actions'
import { selectAccountData, selectPerpsProvider } from 'state/futures/common/selectors'
import { setLeverageSide, setTradePanelDrawerOpen } from 'state/futures/reducer'
import { useAppDispatch, useAppSelector } from 'state/hooks'
import { selectIsWatcherMode, selectWallet } from 'state/wallet/selectors'
import { FixedFooterMixin } from 'styles/common'
import { providerIsCrossMargin } from 'utils/futures'

import { useMarketPageRoute } from 'hooks/useMarketPageRoute'
import MobileMenuModal from './MobileMenuModal'
import MobileWalletButton from './MobileWalletButton'

const MobileUserMenu: FC = () => {
	const [isOpen, toggleOpen] = useReducer((s) => !s, false)
	const { t } = useTranslation()
	const { asPath } = useRouter()
	const dispatch = useAppDispatch()
	const { isWalletConnected, login } = Connector.useContainer()
	const isWatcherMode = useAppSelector(selectIsWatcherMode)
	const walletAddress = useAppSelector(selectWallet)
	const { ensName } = useENS((walletAddress || '') as string)
	const marginAccount = useAppSelector(selectAccountData)
	const provider = useAppSelector(selectPerpsProvider)
	const marketPageRoute = useMarketPageRoute()

	const handleSideSelect = useCallback(
		(side: PositionSide) => () => {
			dispatch(setLeverageSide(side))
			dispatch(setTradePanelDrawerOpen(true))
		},
		[dispatch]
	)

	const showLongShortButtons = useMemo(() => {
		return !!marginAccount && !!isWalletConnected
	}, [isWalletConnected, marginAccount])

	const handleConnectOrCreateAccount = useCallback(() => {
		if (!isWalletConnected) {
			login()
		}

		if (!marginAccount && isWalletConnected) {
			dispatch(setTradePanelDrawerOpen(false))
			providerIsCrossMargin(provider)
				? dispatch(setOpenModal('futures_cross_margin_onboard'))
				: dispatch(createIsolatedMarginAccount({ provider }))
		}
	}, [isWalletConnected, marginAccount, dispatch, provider, login])

	return (
		<>
			<MobileFooterContainer>
				<MobileFooterIconContainer onClick={toggleOpen}>
					{isOpen ? <CloseIcon /> : <MenuIcon />}
				</MobileFooterIconContainer>
				<MobileFooterSeparator />
				<MobileFooterRight>
					{window.location.pathname === ROUTES.Home.Root ? (
						<Link href={marketPageRoute()}>
							<StyledLinkButton size="small">{t('homepage.nav.start-trade')}</StyledLinkButton>
						</Link>
					) : asPath.split('/').includes('market') && !isOpen ? (
						isWatcherMode ? (
							<ErrorView
								onClick={() => dispatch(setOpenModal('watch_or_connect_wallet'))}
								containerStyle={watcherModeWarningStyle}
								message={t('futures.market.trade.button.watching-address', {
									address: ensName || truncateAddress((walletAddress || '') as string),
								})}
								messageType="warn"
							/>
						) : (
							<PositionButtonsContainer lsButtons={showLongShortButtons}>
								{showLongShortButtons ? (
									<>
										<Button
											size="small"
											variant="long"
											fontSize={13}
											fullWidth
											onClick={handleSideSelect(PositionSide.LONG)}
										>
											Long
										</Button>
										<Button
											size="small"
											variant="short"
											fontSize={13}
											fullWidth
											onClick={handleSideSelect(PositionSide.SHORT)}
										>
											Short
										</Button>
									</>
								) : (
									<>
										<StyledTransactionButton
											data-testid="open-cross-margin-onboard-modal"
											onClick={handleConnectOrCreateAccount}
											variant="highlight"
											fullWidth
											size="medium"
										>
											{isWalletConnected
												? t('futures.modals.onboard.create-account')
												: t('common.wallet.connect-wallet')}
										</StyledTransactionButton>
										<Spacer height={15} />
									</>
								)}
							</PositionButtonsContainer>
						)
					) : (
						<MobileWalletButton toggleModal={toggleOpen} />
					)}
				</MobileFooterRight>
			</MobileFooterContainer>
			{isOpen && <MobileMenuModal onDismiss={toggleOpen} />}
		</>
	)
}

const watcherModeWarningStyle = {
	justifyContent: 'center',
	display: 'flex',
	height: '10px',
	width: '100%',
	alignItems: 'center',
	margin: '0px',
}

const MobileFooterContainer = styled.div`
	${FixedFooterMixin};
	display: flex;
	align-items: center;
	border-top: ${(props) => props.theme.colors.selectedTheme.newTheme.border.style};
	padding: 15px 20px;
	background-color: ${(props) => props.theme.colors.selectedTheme.darkBackground};
	z-index: ${zIndex.MOBILE_FOOTER};
	height: ${MOBILE_FOOTER_HEIGHT};
`

const MobileFooterIconContainer = styled.div`
	width: 25px;
`

const MobileFooterSeparator = styled.div`
	margin: 0 20px;
	height: 32px;
	width: 1px;
	background-color: ${(props) => props.theme.colors.selectedTheme.newTheme.border.color};
`

const MobileFooterRight = styled.div`
	display: flex;
	flex-grow: 1;
	justify-content: flex-end;
	align-items: center;
`

const PositionButtonsContainer = styled.div<{ lsButtons: boolean }>`
	display: flex;
	width: 100%;
	flex-direction: row;
	${(props) => props.lsButtons && 'gap: 10px;'}
`

const StyledTransactionButton = styled(TransactionButton)`
	height: 44px;
`

const StyledLinkButton = styled(Button)`
	border-radius: 4px;
`

export default MobileUserMenu
